/**
 * Created by gschulz on 21.04.15.
 */
var AircraftTankFill = (function () {

    var _private = {};

    _private.instance = this;

    /**
     * Complete a given tank array
     *
     * @param {Array} inputTanks
     * @param {object} tankStructure
     * @returns {*}
     */
    this.fillTanksByStructure = function (inputTanks, tankStructure) {
        var inputTanksObject = _private.setTankIdsToIndex(inputTanks);
        _private.prepareStructure(tankStructure);
        $.each(tankStructure.end_tanks, function (i, endTank) {
            _private.fillRecursive(endTank.id, tankStructure, inputTanksObject);
        });
        return _private.extractOutputTanks(tankStructure);
    };

    /**
     *
     * @param fuelLevel
     * @param {object} tankStructure
     * @returns {*}
     */
    this.fillTanksByValue = function (fuelLevel, tankStructure) {
        _private.prepareStructure(tankStructure);
        if (fuelLevel < tankStructure.total.unusable) {
            fuelLevel = tankStructure.total.unusable;
        }
        fuelLevel -= tankStructure.total.unusable;
        var tanks = [];
        $.each(tankStructure.engine_lines, function (i, line) {
            tanks.push(tankStructure.tanks[line.source]);
        });
        _private.sortTanksBy(tanks, 'capacity');
        _private.fillTanksByValueRecursive(tanks, tankStructure, fuelLevel);
        return _private.extractOutputTanks(tankStructure);
    };

    /**
     *
     * @param tanks
     * @param {object} tankStructure
     * @param fuelLevel
     * @returns {*}
     */
    _private.fillTanksByValueRecursive = function (tanks, tankStructure, fuelLevel) {
        var nextTanks = [];
        var nextFuelLevel = fuelLevel;
        _private.sortTanksBy(tanks, 'capacity');
        $.each(tanks, function (i, tank) {
            var res = _private.fillByValue(tank, tankStructure, (fuelLevel / tanks.length));
            nextFuelLevel -= res.filled;
            $.each(res.parents, function (i, tank) {
                nextTanks.push(tank);
            });
        });
        if (nextFuelLevel > 0) {
            var notEmpty = [];
            $.each(tanks, function (i, tank) {
                if (tank.fuel_level < tank.capacity) {
                    notEmpty.push(tank);
                }
            });
            var tmp = nextFuelLevel;
            $.each(notEmpty, function (i, tank) {
                var res = _private.fillByValue(tank, tankStructure, (tmp / notEmpty.length));
                nextFuelLevel -= res.filled;
            });
        }
        if (nextFuelLevel > 0 && nextTanks.length) {
            nextFuelLevel = _private.fillTanksByValueRecursive(nextTanks, tankStructure, nextFuelLevel);
        }
        return nextFuelLevel;
    };

    /**
     *
     * @param tank
     * @param {object} structure
     * @param fuelLevel
     * @returns {*}
     */
    _private.fillByValue = function (tank, structure, fuelLevel) {
        var res = {
            filled:     0,
            fuel_level: fuelLevel,
            parents:    {}
        };
        if (typeof tank === 'undefined') {
            return false;
        }
        if (typeof tank.fuel_level === 'undefined') {
            tank.fuel_level = tank.unusable;
        }
        if ((tank.fuel_level + fuelLevel) > (tank.capacity)) {
            res.filled = Math.abs(tank.fuel_level - tank.capacity);
            tank.fuel_level = tank.capacity;
            fuelLevel -= (tank.capacity - tank.unusable);
        } else {
            tank.fuel_level += fuelLevel;
            res.filled = fuelLevel;
            fuelLevel = 0;
        }
        tank.fuel_level = Math.round(tank.fuel_level * 1000) / 1000;
        res.fuel_level = fuelLevel;
        if (typeof tank.parents === 'undefined') {
            return res;
        }
        $.each(tank.parents, function (i, parent) {
            res.parents[parent.source] = structure.tanks[parent.source];
        });
        return res;
    };

    /**
     *  Sort Tanks by given Field
     *
     * @param {Array} tanks
     * @param {string} field
     */
    _private.sortTanksBy = function (tanks, field) {
        tanks.sort(function (a, b) {
            if (a[field] < b[field]) return -1;
            if (a[field] > b[field]) return 1;
            return 0;
        });
    };

    /**
     * Fill tanks by Fuel-Level
     *
     * @param {int} tankId
     * @param {object} structure
     * @param {number} fuelLevel
     * @returns {number}
     */
    _private.fillRecursiveByValue = function (tankId, structure, fuelLevel) {
        var tank = structure.tanks[tankId];
        if (typeof tank === 'undefined') {
            return fuelLevel;
        }
        if (typeof tank.fuel_level === 'undefined') {
            tank.fuel_level = 0;
        }
        if ((tank.fuel_level + fuelLevel) > (tank.capacity - tank.unusable)) {
            tank.fuel_level = tank.capacity;
            fuelLevel -= tank.capacity - tank.unusable;
        } else {
            tank.fuel_level += fuelLevel;
            fuelLevel = 0;
        }
        tank.fuel_level = Math.round(tank.fuel_level * 1000) / 1000;
        if (typeof tank.parents === 'undefined') {
            return fuelLevel;
        }
        $.each(tank.parents, function (i, parent) {
            console.log(fuelLevel);
            fuelLevel = _private.fillRecursiveByValue(parent.source, structure, fuelLevel);
        });
        return fuelLevel;
    };

    /**
     * Fill the tanks
     *
     * @param {int} tankId
     * @param {Object} structure
     * @param {Array|Object} inputTanks
     * @param {Object} [childTank]
     */
    _private.fillRecursive = function (tankId, structure, inputTanks, childTank) {
        var tank = structure.tanks[tankId];
        if (typeof tank === 'undefined') {
            return;
        }
        var fuel = tank.unusable;
        if (_private.isInputFuelDefined(tank, inputTanks)) {
            var inputFuel = _private.getInputFuel(tank, inputTanks);
            if (inputFuel > fuel) {
                fuel = inputFuel;
            }
        } else {
            if (typeof childTank !== 'undefined' && childTank.fuel_level > childTank.unusable) {
                fuel = tank.capacity;
            }
        }
        tank.fuel_level = fuel;
        if (typeof tank.children === 'undefined') {
            return;
        }
        $.each(tank.children, function (i, child) {
            _private.fillRecursive(child.target, structure, inputTanks, tank);
        });
    };

    /**
     * Add some meta-data to Structure
     *
     * @param {object} tankStructure
     */
    _private.prepareStructure = function (tankStructure) {
        if(tankStructure.end_tanks){
            $.each(tankStructure.tanks,(tId, tank) => {delete tank.fuel_level});
            return;
        }
        tankStructure.tanks = _private.setTankIdsToIndex(tankStructure.tanks);
        tankStructure.engine_lines = [];
        tankStructure.end_tanks = {};
        tankStructure.total = {
            capacity: 0,
            unusable: 0
        };
        $.each(tankStructure.lines, function (i, line) {
            $.each({source: 'children', target: 'parents'}, function (key, type) {
                var tankId = line[key];
                if (typeof tankId !== 'number' ||
                    typeof tankStructure.tanks[tankId] === 'undefined') {
                    tankStructure.engine_lines.push(line);
                    return;
                }
                if (typeof tankStructure.tanks[tankId][type] === 'undefined') {
                    tankStructure.tanks[tankId][type] = [];
                }
                tankStructure.tanks[tankId][type].push(line);
            });
        });
        $.each(tankStructure.tanks, function (i, tank) {
            tankStructure.total.capacity += tank.capacity;
            tankStructure.total.unusable += tank.unusable;
            if (typeof tank.parents === 'undefined') {
                tankStructure.end_tanks[tank.id] = tank;
                return;
            }
            var hasFeedLines = false;
            $.each(tank.parents, function (i, line) {
                if (line.type === 'feed') {
                    hasFeedLines = true;
                }
            });
            if (!hasFeedLines) {
                tankStructure.end_tanks[tank.id] = tank;
            }
        });
    };

    /**
     * Extract tanks from Structure
     *
     * @param {Object} tankStructure
     * @returns {Array}
     */
    _private.extractOutputTanks = function (tankStructure) {
        var outputTanks = [];

        $.each(tankStructure.tanks, function (i, tank) {
            if (typeof tank.fuel_level === 'undefined') {
                tank.fuel_level = tank.unusable;
            }
            outputTanks.push({id: tank.id, fuel_level: tank.fuel_level});
        });
        return outputTanks;
    };

    /**
     * Check if fuel_level is set
     *
     * @param {Object} endTank
     * @param {Array} inputTanks
     * @returns {boolean}
     */
    _private.isInputFuelDefined = function (endTank, inputTanks) {
        return (typeof inputTanks[endTank.id]['fuel_level'] === 'number');
    };

    _private.getInputFuel = function (endTank, inputTanks) {
        return inputTanks[endTank.id]['fuel_level'];
    };

    /**
     * Order Tanks-Array to Object by tank-id
     *
     * @param {Array|Object} tankArray
     * @returns {Object}
     */
    _private.setTankIdsToIndex = function (tankArray) {
        var tankObject = {};
        $.each(tankArray, function (i, o) {
            if (typeof o['id'] === 'undefined') {
                return;
            }
            tankObject[o['id']] = o;
        });
        return tankObject;
    };
});